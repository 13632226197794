declare global {
    interface Window {
        __env__: any;
    }
}

export interface FirebaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
}

export interface ConfigObjectI {
    baseURL: string;
    baseCore: string;
    firebaseConfig: FirebaseConfig;
}

export const getConfigEnv = (key = 'VUE_APP_OP_CONFIG'): ConfigObjectI => {
	const configs = window.__env__
		? JSON.stringify(window.__env__)
		: process.env[key]

	const parseConfig: ConfigObjectI = JSON.parse(configs as string)

	return parseConfig
}