<template>
	<div class="container">
		<img
			class="image"
			:src="require('@/assets/order-plus-with-word-new.png')" />
	</div>
</template>

<style lang="scss" scoped>
.image {
  width: 18rem;
  height: auto;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	background: $bz-primary;
	height: 100%;
	width: 100%
}
</style>