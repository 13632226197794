import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import firebase from 'firebase'
import { baseURL } from '@/config'
import { store } from '@/store'

const apiInstance: AxiosInstance = axios.create({
	baseURL
}) 

apiInstance.interceptors.request.use(async (configs: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
	const { userData } = store.state.user
	if (userData) {
		const token = await firebase.auth().currentUser?.getIdToken()
		configs.headers = {
			...configs.headers,
			Authorization: `Bearer ${token}`
		}
	}
	return configs
})

apiInstance.interceptors.response.use(
	(response) => {        
		return response
	},
	(error) => {
		if (error.response) {
			if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
				// Do seomthing
			}
		}
		return Promise.reject(error)
	}
)

export default apiInstance