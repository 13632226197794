import { ShopKyc } from '@/api/shop/shop.type'
import { SHOP_CREDIT_TYPE } from '@/type/shop'

export type SelectedShopId = string | null
export interface ShopList {
    _id:     string;
    name:    string;
    email:   string;
    phoneNo: string;
    image:   string;
    kyc:     Kyc;
}

export interface Kyc {
    status: string;
}

export type ShopPagination = {
    pages: number;
    totals: number;
}

export enum ACCOUNTING_TYPE {
    BZT = 'BZT',
    BZS = 'BZS',
}  

export interface ShopDetailI {
    _id:           string;
    name:          string;
    desc:          string;
    code:          string;
    email:         string;
    accountingType?: ACCOUNTING_TYPE;
    paymentChannelGateway: string;
    creditType:    SHOP_CREDIT_TYPE;
    credit:        number;
    phoneNo:       string;
    instagramLink: string;
    facebookLink:  string;
    lineLink:      string;
    image:         string;
    createdAt:     Date|string;
    updatedAt:     Date|string;
    users:         any[];
    address:       Address[];
    bank:          Bank[];
    status:        string;
    limitTransferOrder: number;
    referralCode: string;
    article?:    any;
    deleted: boolean;
    lastLoginProvider: string;
}

export interface Address {
    _id:             string;
    deleted:         boolean;
    desc1:           string;
    provinceCode:    number;
    districtCode:    number;
    subDistrictCode: number;
    zipCode:         number;
    updatedAt:       Date;
    createdAt:       Date;
    isSelected:      boolean;
}

export interface Bank {
    _id:              string;
    deleted:          boolean;
    accountName:      string;
    accountNumber:    string;
    accountIssuerId?: string;
    updatedAt:        Date;
    createdAt:        Date;
    accountIssuer:    AccountIssuer;
}

export interface AccountIssuer {
    _id:   string;
    name:  string;
    code:  string;
    image: string;
}

export type State = {
    shops: ShopList[],
    pagination: ShopPagination,
    shopDetail: ShopDetailI|null,
    shopKycDetail: null|ShopKyc;
}

export const state = (): State => ({
	shops: [],
	pagination: {
		pages: 1,
		totals: 1
	},
	shopDetail: null,
	shopKycDetail: null
})
