import { ShopKyc } from '@/api/shop/shop.type'
import { MutationTree } from 'vuex'
import { State, ShopList, ShopPagination, ShopDetailI } from './state'

export enum ShopMutationsTypes {
    UPDATE_SHOP_LIST = 'UPDATE_SHOP_LIST',
	UPDATE_PAGINATION = 'UPDATE_PAGINATION',
	UPDATE_SHOP_DETAIL = 'UPDATE_SHOP_DETAIL',
	UPDATE_SHOP_KYC_DETAIL = 'UPDATE_SHOP_KYC_DETAIL'
}

export type Mutations<S = State> = {
    [ShopMutationsTypes.UPDATE_SHOP_LIST](state: S, payload: ShopList[]): void;
	[ShopMutationsTypes.UPDATE_PAGINATION](state: State, pagination: ShopPagination): void;
	[ShopMutationsTypes.UPDATE_SHOP_DETAIL](state: State, shopDetail: ShopDetailI): void;
	[ShopMutationsTypes.UPDATE_SHOP_KYC_DETAIL](state: State, kycDetail: ShopKyc): void;
}

export const mutations: MutationTree<State> & Mutations = {
	[ShopMutationsTypes.UPDATE_SHOP_LIST](state: State, shops: ShopList[]) {
		state.shops = shops
	},
	[ShopMutationsTypes.UPDATE_PAGINATION](state: State, pagination: ShopPagination) {
		state.pagination = pagination
	},
	[ShopMutationsTypes.UPDATE_SHOP_DETAIL](state: State, shopDetail: ShopDetailI) {
		state.shopDetail = shopDetail
	},
	[ShopMutationsTypes.UPDATE_SHOP_KYC_DETAIL](state: State, kycDetail: ShopKyc) {
		state.shopKycDetail = kycDetail
	}
}