import { RouteRecordRaw } from 'vue-router'

const temp: RouteRecordRaw = {
	path: '/temp',
	name: 'temp',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		// {
		// 	path: '',
		// 	name: 'Remittance ชั่วคราว',
		// 	component: () => import('@/views/temp/reconcile.vue'),
		// }
	]
}
export default temp