import { App } from 'vue'
import { Plus, CircleCheckFilled, Refresh, TrendCharts, Box, ShoppingCart, VideoCamera, Van, View, CloseBold, SoldOut, More, MoreFilled } from '@element-plus/icons'

const initialElementPlusIcon = (app: App<Element>): void => {
	// GLobal Icon
	const icons = [
		Plus,
		CircleCheckFilled,
		Refresh,
		TrendCharts,
		Box,
		ShoppingCart,
		VideoCamera,
		Van,
		View,
		CloseBold,
		SoldOut,
		More,
		MoreFilled,
	]
	
	for (const icon of icons) {
		app.component(icon.name, icon)
	}
}

export default initialElementPlusIcon
