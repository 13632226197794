import { RouteRecordRaw } from 'vue-router'

export interface BreadCrumbParams {
	title: string;
	path: string;
}

const setBreadCrumbs = (route: BreadCrumbParams): BreadCrumbParams => route

const shopRoute: RouteRecordRaw = {
	path: '/shop',
	name: 'MainShop',
	meta: {
		breadcrumb: {
			title: 'ร้านค้า',
			path: '/shop'
		}
	},
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'ManageShop',
			component: () => import('@/views/shop/index.vue')
		},
		{
			path: ':shopId',
			name: 'ShopDetail',
			meta: {
				paramsKey: 'shopId',
				breadcrumb: setBreadCrumbs
			},
			component: () => import('@/views/shop/detail.vue')
		}
	]
}



export default shopRoute