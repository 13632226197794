import { store } from '@/store'
import { RouteRecordRaw } from 'vue-router'

const reconcile: RouteRecordRaw = {
	path: '/reconcile',
	name: 'Reconcile',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'กระทบยอด',
			component: () => import('@/views/reconcile/index.vue'),
			beforeEnter: () => {
				const { claims } = store.state.user

				const allowRole = ['owner']
				// if (!allowRole.includes(claims.role)) {
				// 	return '/shop'
				// }
			}
		},
		{
			path: 'hold-cod',
			name: 'ร้านโดน HOLD ยอด COD',
			component: () => import('@/views/reconcile/reconcileHold/index.vue'),
		},
		{
			path: 'shop-hold-cod/:shopId',
			name: 'ร้านทั้งหมดที่โดน HOLD ยอด COD',
			component: () => import('@/views/reconcile/reconcileHold/_shopId.vue'),
		},
		{
			path: ':reconcileId/shop',
			name: 'กระทบยอดร้านทั้งหมด',
			component: () => import('@/views/reconcile/_reconcileId/index.vue')
		},
		{
			path: ':reconcileId/shop/:shopId',
			name: 'กระทบยอดต่อร้านค้า',
			component: () => import('@/views/reconcile/_reconcileId/_shopId.vue')
		},
		{
			path: ':logReconcileId/preview',
			name: 'reconcilePreview',
			component: () => import('@/views/reconcile/_reconcileId/preview.vue')
		},
	]
}
export default reconcile