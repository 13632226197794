import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/storage'

import { firebaseConfig } from '@/config'

const initialFirebase = (): void => {
	firebase.initializeApp(firebaseConfig)
	firebase.analytics()
}

export default initialFirebase