import { App } from 'vue'
import ElementPlus from 'element-plus'
import th from 'element-plus/es/locale/lang/th'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'

const initialElementPlus = (app: App<Element>): void => {
	app.use(ElementPlus, {
		locale: th,
		size: 'medium',
		zIndex: 3000,
	})
}

export default initialElementPlus