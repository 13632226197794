import { RouteRecordRaw } from 'vue-router'


const claim: RouteRecordRaw = {
	path: '/claim',
	name: 'Claim',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'ClaimList',
			component: () => import('@/views/claim/index.vue')
		}
	]
}



export default claim