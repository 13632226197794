import firebase from 'firebase'

export type UserData = firebase.User & { claims: any } | null
export type AccessToken = string | null

export type State = {
    userData: UserData;
    accessToken: AccessToken;
    claims: any
}

export const state = (): State => ({
	userData: null,
	accessToken: null,
	claims: null
})
