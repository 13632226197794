import { MutationTree } from 'vuex'
import { State, UserData, AccessToken } from './state'

export enum UserMutationsTypes {
    UPDATE_USER_DATA = 'USER/UPDATE_USER_DATA',
    UPDATE_PROVIDER_ACCESS_TOKEN = 'USER/UPDATE_PROVIDER_ACCESS_TOKEN',
	UPDATE_USER_CLAIMS = 'USER/UPDATE_USER_CLAIMS'
}

export type Mutations<S = State> = {
    [UserMutationsTypes.UPDATE_USER_DATA](state: S, payload: UserData): void,
    [UserMutationsTypes.UPDATE_PROVIDER_ACCESS_TOKEN](state: S, accessToken: AccessToken): void,
	[UserMutationsTypes.UPDATE_USER_CLAIMS](state: S, claims: any): void,
}

export const mutations: MutationTree<State> & Mutations = {
	[UserMutationsTypes.UPDATE_USER_DATA](state: State, payload: UserData) {
		state.userData = payload
	},
	[UserMutationsTypes.UPDATE_PROVIDER_ACCESS_TOKEN](state: State, accessToken: AccessToken) {
		state.accessToken = accessToken
	},
	[UserMutationsTypes.UPDATE_USER_CLAIMS](state: State, claims: any) {
		state.claims = claims
	}

}