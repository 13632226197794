import { ActionContext, ActionTree } from 'vuex'
// import { RootState } from '@/store'
import { Mutations, ShopMutationsTypes } from './mutations'

import { State } from './state'
import { RootState } from '@/store'
import { getKycShopByShopId, getShopDetailById, getShopList } from '@/api/shop/shop.api'
import { GetShopQueryParams } from '@/api/shop/shop.type'

export enum ShopActionTypes {
    GET_ALL_SHOP = 'shop/GET_ALL_SHOP',
	GET_SHOP_DETAIL = 'shop/GET_SHOP_DETAL',
	GET_SHOP_KYC_DETAIL = 'shop/GET_SHOP_KYC_DETAIL'
}

export type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload?: Parameters<Mutations[K]>[1],
        ): ReturnType<Mutations[K]>
    } & Omit<ActionContext<State, RootState>, 'commit'>
    
export interface Actions {
    [ShopActionTypes.GET_ALL_SHOP](context: AugmentedActionContext, params: GetShopQueryParams): Promise<void>|void;
	[ShopActionTypes.GET_SHOP_DETAIL](context: AugmentedActionContext, shopId: string): Promise<void>|void;
	[ShopActionTypes.GET_SHOP_KYC_DETAIL](context: AugmentedActionContext, shopId: string): Promise<void>|void;
}

export const actions: ActionTree<State, RootState> & Actions = {
	async [ShopActionTypes.GET_ALL_SHOP]({ commit }: AugmentedActionContext, params: GetShopQueryParams) {
		//
		try {
			const { data: shopData } = await getShopList(params)

			commit(ShopMutationsTypes.UPDATE_SHOP_LIST, shopData.data)
			commit(ShopMutationsTypes.UPDATE_PAGINATION, shopData.pagination)
		} catch (error) {
			return Promise.reject(error)
		}
	},
	async [ShopActionTypes.GET_SHOP_DETAIL]({ commit }: AugmentedActionContext, shopId: string) {
		try {
			const { data: shopDetail } = await getShopDetailById(shopId)
			commit(ShopMutationsTypes.UPDATE_SHOP_DETAIL, shopDetail)
		} catch (error) {
			return Promise.reject(error)
		}
	},
	async [ShopActionTypes.GET_SHOP_KYC_DETAIL]({ commit }: AugmentedActionContext, shopId: string) {
		try {
			const { data: kycDetail } = await getKycShopByShopId(shopId)
			commit(ShopMutationsTypes.UPDATE_SHOP_KYC_DETAIL, kycDetail)
		} catch (error) {
			return Promise.reject(error)
		}
	}

}
