import { RouteRecordRaw } from 'vue-router'

export interface BreadCrumbParams {
	title: string;
	path: string;
}

const bookingRoute: RouteRecordRaw = {
	path: '/booking',
	name: 'เรียกรถเข้ารับ',
	meta: {
		breadcrumb: {
			title: 'เรียกรถเข้ารับ',
			path: '/booking'
		}
	},
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'Booking',
			component: () => import('@/views/booking/index.vue')
		}
	]
}



export default bookingRoute