import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { store as user, State as UserState, UserStore } from './modules/user/index'
import { store as shop, State as ShopState, ShopStore } from './modules/shop/index'
import { store as product, State as ProductState, ProductStore } from './modules/product/index'

const persistedPlugins = createPersistedState({
	key: 'order-plus-admin',
	paths: ['shop.selectedShopId', 'user']
})

export type RootState = {
	user: UserState,
	shop: ShopState,
	product: ProductState
}

const plugins = [persistedPlugins]

export type Store = UserStore<Pick<RootState, 'user'>>
	& ShopStore<Pick<RootState, 'shop'>>
	& ProductStore<Pick<RootState, 'product'>>

export const store = createStore({
	modules: {
		user,
		shop,
		product
	},
	plugins
})

export const useStore = (): Store => {
	return store as Store
}