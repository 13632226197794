import { RouteRecordRaw } from 'vue-router'


const courier: RouteRecordRaw = {
	path: '/courier',
	name: 'courier',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: 'issue-zone-list',
			name: 'courierIssueZoneList',
			component: () => import('@/views/courier/issueZone.vue')
		}
	]
}



export default courier