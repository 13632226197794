import { createApp } from 'vue'
import firebase from 'firebase'
import App from './App.vue'
import FirstLoadingScreen from './FirstLoadingScreen.vue'
import router from './router'
import { store } from './store'
import { UserMutationsTypes } from '@/store/modules/user/mutations'
import initialMiddleware from './middlewares'
// Plugins
import initialElementPlus from '@/plugins/element-plus'
import initialElementPlusIcon from '@/plugins/icons'
import initialFirebase from '@/plugins/firebase'
import initialVueBarcode from '@/plugins/vue-barcode'
import initialVueQrCode from '@/plugins/vue-qrcode'
//Global Style
import '@/assets/sass/globalStyles.scss'
import initialVueClipboard from './plugins/vue-clipboard'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { useCourierStore } from './pinia/courier/courier.pinia'

let count = 0

function startVueApp() {
	const app = createApp(App)
	// usepinia
	const pinia = createPinia()
	pinia.use(piniaPluginPersistedstate)
	app.use(pinia)
	
	const loadingScreen = createApp(FirstLoadingScreen)
	const courierStore = useCourierStore()
	
	// Mount First Loading
	loadingScreen.mount('#app')
	// Initial Plugins
	// Subscribe firebase
	initialFirebase()
	
	firebase.auth().onAuthStateChanged(async (user) => {
		count++
		// Intial APp but if User trigger change again dont do Mount App
		if (user) {
			const result = await user?.getIdTokenResult()
			store.commit(UserMutationsTypes.UPDATE_USER_CLAIMS, result.claims)
			await Promise.all([courierStore.getCourierList()])
		}
		store.commit(UserMutationsTypes.UPDATE_USER_DATA, user)
		if (count === 1) {
			loadingScreen.unmount()
			//initial global middleware
			initialElementPlus(app)
			initialElementPlusIcon(app)
			initialMiddleware(router)
			initialVueBarcode(app)
			initialVueQrCode(app)
			initialVueClipboard(app)
			app.use(store)
			app.use(router)
			// Unmount loading page
			// Mount App
			app.mount('#app')
		}
	})
}

startVueApp()
