import { RootState } from '@/store'
import { GetterTree } from 'vuex'
import { State } from './state'

export enum ProductGetterTypes {
    PRODUCT_PAGE = 'PRODUCT_PAGE',
    PRODUCT_TOTALPAGE = 'PRODUCT_TOTALPAGE',
    PRODUCT_LIST = 'PRODUCT_LIST'
}

export type Getters<S = State> = {
    [ProductGetterTypes.PRODUCT_PAGE](state: S): number
    [ProductGetterTypes.PRODUCT_TOTALPAGE](state: S): number
}

export const getters: GetterTree<State, RootState> & Getters = {
	[ProductGetterTypes.PRODUCT_PAGE](state: State) {
		return state.productPage
	},
	[ProductGetterTypes.PRODUCT_TOTALPAGE](state: State) {
		return state.productTotalPage
	},
}
