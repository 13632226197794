import { Router } from 'vue-router'
import { store } from '@/store'
// auth middleware
// Guard
const initialMiddleware = (router: Router): void => {
	//
	router.beforeEach((to, from) => {
		if (!store.state.user.userData && to.path !== '/login') {
			return '/login'
		}
	})
}

export default initialMiddleware
