import axios from '../instance'

export const getCourierList = async (): Promise<any> => {
	try {
		const response = await axios.get('/courier', {
			params: { type: 'all' },
		})
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getCourierIssueZone = async () => {
	try {
		const response = await axios.get('/address/issue-zone')
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const addIssueZone = async (payload: any) => {
	try {
		const response = await axios.post('/admin/issue-zone/add', payload)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteIssueZone = async (payload: any) => {
	try {
		const response = await axios.post('/admin/issue-zone/delete', payload)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
