import { RootState } from '@/store'
import { GetterTree } from 'vuex'
import { State } from './state'

export interface CurrentShop {
	_id: string;
	name: string;
	image: string;
}

export enum ShopGetterTypes {
    CURRENT_SHOP = 'CURRENT_SHOP',
}

export type Getters<S = State> = {
    // [ShopGetterTypes.CURRENT_SHOP](state: S): CurrentShop|undefined,
}

export const getters: GetterTree<State, RootState> & Getters = {
	
}
