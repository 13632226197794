import { RouteRecordRaw } from 'vue-router'

const system: RouteRecordRaw = {
	path: '/system',
	name: 'system',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: 'setting-config',
			name: 'systemSettingConfig',
			component: () => import('@/views/system/setting-config.vue'),
		},
	]
}
export default system