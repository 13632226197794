import { store } from '@/store'
import { RouteRecordRaw } from 'vue-router'

const order: RouteRecordRaw = {
	path: '/dashboard',
	name: 'Dasboard',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'ภาพรวมระบบ',
			component: () => import('@/views/dashboard.vue'),
			beforeEnter: () => {
				const { claims } = store.state.user

				const allowRole = ['owner']
				if (!allowRole.includes(claims.role)) {
					return '/shop'
				}
			}
		}
	]
}
export default order