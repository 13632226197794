import { MutationTree } from 'vuex'
import { State } from './state'

export enum ProductMutationsTypes {
    UPDATE_PRODUCT_PAGE = 'UPDATE_PRODUCT_PAGE',
    UPDATE_PRODUCT_TOTALPAGE = 'UPDATE_PRODUCT_TOTALPAGE',
    UPDATE_PRODUCT_LIST = 'UPDATE_PRODUCT_LIST'
}

export type Mutations<S = State> = {
    [ProductMutationsTypes.UPDATE_PRODUCT_PAGE](state: S, payload: number): void
    [ProductMutationsTypes.UPDATE_PRODUCT_TOTALPAGE](state: S, payload: number): void
}

export const mutations: MutationTree<State> & Mutations = {
	[ProductMutationsTypes.UPDATE_PRODUCT_PAGE](state: State, productPage: number) {
		state.productPage = productPage
	},
	[ProductMutationsTypes.UPDATE_PRODUCT_TOTALPAGE](state: State, productTotalPage: number) {
		state.productTotalPage = productTotalPage
	},
}