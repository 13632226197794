import { RouteRecordRaw } from 'vue-router'

const billPayment: RouteRecordRaw = {
	path: '/bill-payment',
	name: 'Billpayment',
	component: () => import('@/layout/LayoutDefault.vue'),
	children: [
		{
			path: '',
			name: 'BillPaymentMain',
			component: () => import('@/views/bill-payment/index.vue'),
		},
		{
			path: ':billPaymentId',
			name: 'billPaymentId',
			component: () => import('@/views/bill-payment/_billPaymentId.vue')
		}
	]
}
export default billPayment